import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0185a94e = () => interopDefault(import('../client/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _c3f362a4 = () => interopDefault(import('../client/pages/about-menu.vue' /* webpackChunkName: "pages/about-menu" */))
const _0e6ef00c = () => interopDefault(import('../client/pages/articles.vue' /* webpackChunkName: "pages/articles" */))
const _066b37e2 = () => interopDefault(import('../client/pages/business.vue' /* webpackChunkName: "pages/business" */))
const _7a6aa604 = () => interopDefault(import('../client/pages/cases.vue' /* webpackChunkName: "pages/cases" */))
const _085932e7 = () => interopDefault(import('../client/pages/cases-brandbook.vue' /* webpackChunkName: "pages/cases-brandbook" */))
const _6404a634 = () => interopDefault(import('../client/pages/cdn-trace.vue' /* webpackChunkName: "pages/cdn-trace" */))
const _5e8b1fda = () => interopDefault(import('../client/pages/changepassword.vue' /* webpackChunkName: "pages/changepassword" */))
const _5a252b2a = () => interopDefault(import('../client/pages/community.vue' /* webpackChunkName: "pages/community" */))
const _9b10e650 = () => interopDefault(import('../client/pages/conferences.vue' /* webpackChunkName: "pages/conferences" */))
const _34dd8657 = () => interopDefault(import('../client/pages/confidential.vue' /* webpackChunkName: "pages/confidential" */))
const _7e5ebe73 = () => interopDefault(import('../client/pages/cookie.vue' /* webpackChunkName: "pages/cookie" */))
const _6ac63542 = () => interopDefault(import('../client/pages/design-system.vue' /* webpackChunkName: "pages/design-system" */))
const _0dd9a8a2 = () => interopDefault(import('../client/pages/design-system/index.vue' /* webpackChunkName: "pages/design-system/index" */))
const _f5c0bc5e = () => interopDefault(import('../client/pages/design-system/buttons.vue' /* webpackChunkName: "pages/design-system/buttons" */))
const _306bb6b3 = () => interopDefault(import('../client/pages/design-system/cards.vue' /* webpackChunkName: "pages/design-system/cards" */))
const _5d450e60 = () => interopDefault(import('../client/pages/design-system/colors.vue' /* webpackChunkName: "pages/design-system/colors" */))
const _c70034c8 = () => interopDefault(import('../client/pages/design-system/hints.vue' /* webpackChunkName: "pages/design-system/hints" */))
const _27b3222c = () => interopDefault(import('../client/pages/design-system/icons.vue' /* webpackChunkName: "pages/design-system/icons" */))
const _51b50e6e = () => interopDefault(import('../client/pages/design-system/inputs.vue' /* webpackChunkName: "pages/design-system/inputs" */))
const _67c95cac = () => interopDefault(import('../client/pages/design-system/messages.vue' /* webpackChunkName: "pages/design-system/messages" */))
const _a3fc30f4 = () => interopDefault(import('../client/pages/design-system/og-image.vue' /* webpackChunkName: "pages/design-system/og-image" */))
const _8a45ac0c = () => interopDefault(import('../client/pages/design-system/pagination.vue' /* webpackChunkName: "pages/design-system/pagination" */))
const _bea1a23c = () => interopDefault(import('../client/pages/design-system/segmented.vue' /* webpackChunkName: "pages/design-system/segmented" */))
const _face6c44 = () => interopDefault(import('../client/pages/design-system/tabs.vue' /* webpackChunkName: "pages/design-system/tabs" */))
const _e3d7f4ec = () => interopDefault(import('../client/pages/design-system/text-input.vue' /* webpackChunkName: "pages/design-system/text-input" */))
const _213d8413 = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _184232a8 = () => interopDefault(import('../client/pages/events.vue' /* webpackChunkName: "pages/events" */))
const _21381b2b = () => interopDefault(import('../client/pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _0cad7b1a = () => interopDefault(import('../client/pages/events/certificates.vue' /* webpackChunkName: "pages/events/certificates" */))
const _1327dedd = () => interopDefault(import('../client/pages/events/planned.vue' /* webpackChunkName: "pages/events/planned" */))
const _56770d43 = () => interopDefault(import('../client/pages/events/visited.vue' /* webpackChunkName: "pages/events/visited" */))
const _6f315acd = () => interopDefault(import('../client/pages/forgotpassword.vue' /* webpackChunkName: "pages/forgotpassword" */))
const _05cfba65 = () => interopDefault(import('../client/pages/help-center/index.vue' /* webpackChunkName: "pages/help-center/index" */))
const _621ef930 = () => interopDefault(import('../client/pages/learning/index.vue' /* webpackChunkName: "pages/learning/index" */))
const _2a35b1ac = () => interopDefault(import('../client/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2b46313b = () => interopDefault(import('../client/pages/my.vue' /* webpackChunkName: "pages/my" */))
const _74f99f7e = () => interopDefault(import('../client/pages/my/index.vue' /* webpackChunkName: "pages/my/index" */))
const _5da998c1 = () => interopDefault(import('../client/pages/my/articles.vue' /* webpackChunkName: "pages/my/articles" */))
const _1fd60207 = () => interopDefault(import('../client/pages/my/companies.vue' /* webpackChunkName: "pages/my/companies" */))
const _7ff7acbc = () => interopDefault(import('../client/pages/my/feed.vue' /* webpackChunkName: "pages/my/feed" */))
const _63851094 = () => interopDefault(import('../client/pages/my/jobsearch/index.vue' /* webpackChunkName: "pages/my/jobsearch/index" */))
const _2ec0b218 = () => interopDefault(import('../client/pages/my/portfolio.vue' /* webpackChunkName: "pages/my/portfolio" */))
const _31220cea = () => interopDefault(import('../client/pages/my/settings/index.vue' /* webpackChunkName: "pages/my/settings/index" */))
const _630599a1 = () => interopDefault(import('../client/pages/my/subscription.vue' /* webpackChunkName: "pages/my/subscription" */))
const _bdb4244e = () => interopDefault(import('../client/pages/my/transactions.vue' /* webpackChunkName: "pages/my/transactions" */))
const _1d4e6afd = () => interopDefault(import('../client/pages/my/unsubscribe.vue' /* webpackChunkName: "pages/my/unsubscribe" */))
const _76521b01 = () => interopDefault(import('../client/pages/my/jobsearch/contacts.vue' /* webpackChunkName: "pages/my/jobsearch/contacts" */))
const _cb418e54 = () => interopDefault(import('../client/pages/my/jobsearch/history.vue' /* webpackChunkName: "pages/my/jobsearch/history" */))
const _fc8ccdb0 = () => interopDefault(import('../client/pages/my/jobsearch/resumes.vue' /* webpackChunkName: "pages/my/jobsearch/resumes" */))
const _3be97b08 = () => interopDefault(import('../client/pages/my/settings/appearance.vue' /* webpackChunkName: "pages/my/settings/appearance" */))
const _342a922a = () => interopDefault(import('../client/pages/my/settings/contacts.vue' /* webpackChunkName: "pages/my/settings/contacts" */))
const _6b0b507c = () => interopDefault(import('../client/pages/my/settings/cookie.vue' /* webpackChunkName: "pages/my/settings/cookie" */))
const _3264d043 = () => interopDefault(import('../client/pages/my/settings/delete.vue' /* webpackChunkName: "pages/my/settings/delete" */))
const _7b2f33dc = () => interopDefault(import('../client/pages/my/settings/digest.vue' /* webpackChunkName: "pages/my/settings/digest" */))
const _2e1f77f0 = () => interopDefault(import('../client/pages/my/settings/enter.vue' /* webpackChunkName: "pages/my/settings/enter" */))
const _d7d55c00 = () => interopDefault(import('../client/pages/my/settings/general.vue' /* webpackChunkName: "pages/my/settings/general" */))
const _0ca32f21 = () => interopDefault(import('../client/pages/my/settings/interests.vue' /* webpackChunkName: "pages/my/settings/interests" */))
const _204afe00 = () => interopDefault(import('../client/pages/my/settings/notifications.vue' /* webpackChunkName: "pages/my/settings/notifications" */))
const _0eb25f15 = () => interopDefault(import('../client/pages/ps/index.vue' /* webpackChunkName: "pages/ps/index" */))
const _3158f500 = () => interopDefault(import('../client/pages/rate-retention.vue' /* webpackChunkName: "pages/rate-retention" */))
const _1fe5a6ac = () => interopDefault(import('../client/pages/recruiter.vue' /* webpackChunkName: "pages/recruiter" */))
const _641cfb5c = () => interopDefault(import('../client/pages/refresh.vue' /* webpackChunkName: "pages/refresh" */))
const _1bccb508 = () => interopDefault(import('../client/pages/registration.vue' /* webpackChunkName: "pages/registration" */))
const _4d67f320 = () => interopDefault(import('../client/pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _69897b70 = () => interopDefault(import('../client/pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _3588a7a4 = () => interopDefault(import('../client/pages/transactions.vue' /* webpackChunkName: "pages/transactions" */))
const _8163c4cc = () => interopDefault(import('../client/pages/vacancies.vue' /* webpackChunkName: "pages/vacancies" */))
const _7d279567 = () => interopDefault(import('../client/pages/videos.vue' /* webpackChunkName: "pages/videos" */))
const _79dc6f36 = () => interopDefault(import('../client/pages/admin/access/index.vue' /* webpackChunkName: "pages/admin/access/index" */))
const _ced5f7c2 = () => interopDefault(import('../client/pages/admin/companies/index.vue' /* webpackChunkName: "pages/admin/companies/index" */))
const _93cc3256 = () => interopDefault(import('../client/pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _5d76553f = () => interopDefault(import('../client/pages/admin/library/index.vue' /* webpackChunkName: "pages/admin/library/index" */))
const _4ad35226 = () => interopDefault(import('../client/pages/admin/poll-after-trial.vue' /* webpackChunkName: "pages/admin/poll-after-trial" */))
const _6e2e5a96 = () => interopDefault(import('../client/pages/admin/publications.vue' /* webpackChunkName: "pages/admin/publications" */))
const _6fb5be90 = () => interopDefault(import('../client/pages/admin/sales/index.vue' /* webpackChunkName: "pages/admin/sales/index" */))
const _083179fe = () => interopDefault(import('../client/pages/admin/unsubscriptions.vue' /* webpackChunkName: "pages/admin/unsubscriptions" */))
const _39160ee8 = () => interopDefault(import('../client/pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _25806c1a = () => interopDefault(import('../client/pages/admin/vacancies.vue' /* webpackChunkName: "pages/admin/vacancies" */))
const _3a85939a = () => interopDefault(import('../client/pages/conference/cases-marketing-strategy-pr-2021.vue' /* webpackChunkName: "pages/conference/cases-marketing-strategy-pr-2021" */))
const _0cd7e340 = () => interopDefault(import('../client/pages/conference/cases-product-design-2021.vue' /* webpackChunkName: "pages/conference/cases-product-design-2021" */))
const _0cbbb43e = () => interopDefault(import('../client/pages/conference/cases-product-design-2022.vue' /* webpackChunkName: "pages/conference/cases-product-design-2022" */))
const _c7cbe442 = () => interopDefault(import('../client/pages/conference/cases-smpr-2020.vue' /* webpackChunkName: "pages/conference/cases-smpr-2020" */))
const _a6243a42 = () => interopDefault(import('../client/pages/conference/cpractice-2020.vue' /* webpackChunkName: "pages/conference/cpractice-2020" */))
const _5e0fd4c6 = () => interopDefault(import('../client/pages/conference/dysarium.vue' /* webpackChunkName: "pages/conference/dysarium" */))
const _665e3557 = () => interopDefault(import('../client/pages/conference/dysarium-2024.vue' /* webpackChunkName: "pages/conference/dysarium-2024" */))
const _c9387670 = () => interopDefault(import('../client/pages/conference/praktikum-vol-1.vue' /* webpackChunkName: "pages/conference/praktikum-vol-1" */))
const _53fe294e = () => interopDefault(import('../client/pages/learning/brandbook.vue' /* webpackChunkName: "pages/learning/brandbook" */))
const _1efd7b88 = () => interopDefault(import('../client/pages/learning/chart.vue' /* webpackChunkName: "pages/learning/chart" */))
const _413e7796 = () => interopDefault(import('../client/pages/learning/courses.vue' /* webpackChunkName: "pages/learning/courses" */))
const _1d74575e = () => interopDefault(import('../client/pages/learning/courses/_slug.vue' /* webpackChunkName: "pages/learning/courses/_slug" */))
const _760a4250 = () => interopDefault(import('../client/pages/learning/donorua.vue' /* webpackChunkName: "pages/learning/donorua" */))
const _9d37aece = () => interopDefault(import('../client/pages/learning/dysarium-2024.vue' /* webpackChunkName: "pages/learning/dysarium-2024" */))
const _eb407182 = () => interopDefault(import('../client/pages/learning/expert/index.vue' /* webpackChunkName: "pages/learning/expert/index" */))
const _7d0b4372 = () => interopDefault(import('../client/pages/learning/lectures.vue' /* webpackChunkName: "pages/learning/lectures" */))
const _f80863ae = () => interopDefault(import('../client/pages/learning/maps.vue' /* webpackChunkName: "pages/learning/maps" */))
const _5cbd1fa8 = () => interopDefault(import('../client/pages/learning/marathon.vue' /* webpackChunkName: "pages/learning/marathon" */))
const _db9a063c = () => interopDefault(import('../client/pages/learning/masterclasses.vue' /* webpackChunkName: "pages/learning/masterclasses" */))
const _e3b1c460 = () => interopDefault(import('../client/pages/learning/moder/index.vue' /* webpackChunkName: "pages/learning/moder/index" */))
const _c7c5cd84 = () => interopDefault(import('../client/pages/learning/my.vue' /* webpackChunkName: "pages/learning/my" */))
const _9c958d7e = () => interopDefault(import('../client/pages/learning/my/index.vue' /* webpackChunkName: "pages/learning/my/index" */))
const _145bd95a = () => interopDefault(import('../client/pages/learning/my/briefs.vue' /* webpackChunkName: "pages/learning/my/briefs" */))
const _b9af80c6 = () => interopDefault(import('../client/pages/learning/my/certificates.vue' /* webpackChunkName: "pages/learning/my/certificates" */))
const _5f7acd50 = () => interopDefault(import('../client/pages/learning/my/community.vue' /* webpackChunkName: "pages/learning/my/community" */))
const _0444f767 = () => interopDefault(import('../client/pages/learning/my/courses.vue' /* webpackChunkName: "pages/learning/my/courses" */))
const _5a516443 = () => interopDefault(import('../client/pages/learning/my/gains.vue' /* webpackChunkName: "pages/learning/my/gains" */))
const _46b99138 = () => interopDefault(import('../client/pages/learning/my/brief/_slug.vue' /* webpackChunkName: "pages/learning/my/brief/_slug" */))
const _045526fe = () => interopDefault(import('../client/pages/learning/podcasts.vue' /* webpackChunkName: "pages/learning/podcasts" */))
const _174af7ec = () => interopDefault(import('../client/pages/learning/specials.vue' /* webpackChunkName: "pages/learning/specials" */))
const _552958a2 = () => interopDefault(import('../client/pages/learning/subscription.vue' /* webpackChunkName: "pages/learning/subscription" */))
const _735a4b4d = () => interopDefault(import('../client/pages/learning/subscription-poll.vue' /* webpackChunkName: "pages/learning/subscription-poll" */))
const _61971d74 = () => interopDefault(import('../client/pages/learning/trial.vue' /* webpackChunkName: "pages/learning/trial" */))
const _50eed296 = () => interopDefault(import('../client/pages/moder/dashboard.vue' /* webpackChunkName: "pages/moder/dashboard" */))
const _6148d280 = () => interopDefault(import('../client/pages/admin/access/grant.vue' /* webpackChunkName: "pages/admin/access/grant" */))
const _283c3a34 = () => interopDefault(import('../client/pages/admin/library/awarditems.vue' /* webpackChunkName: "pages/admin/library/awarditems" */))
const _2412e5da = () => interopDefault(import('../client/pages/admin/library/baners.vue' /* webpackChunkName: "pages/admin/library/baners" */))
const _319e6da8 = () => interopDefault(import('../client/pages/admin/library/cities.vue' /* webpackChunkName: "pages/admin/library/cities" */))
const _0c946a05 = () => interopDefault(import('../client/pages/admin/library/jobtitles.vue' /* webpackChunkName: "pages/admin/library/jobtitles" */))
const _5342c501 = () => interopDefault(import('../client/pages/admin/library/services.vue' /* webpackChunkName: "pages/admin/library/services" */))
const _69691245 = () => interopDefault(import('../client/pages/admin/library/slides.vue' /* webpackChunkName: "pages/admin/library/slides" */))
const _321d7623 = () => interopDefault(import('../client/pages/admin/library/vacancy-specializations.vue' /* webpackChunkName: "pages/admin/library/vacancy-specializations" */))
const _7593811a = () => interopDefault(import('../client/pages/learning/moder/grant.vue' /* webpackChunkName: "pages/learning/moder/grant" */))
const _34a96a7b = () => interopDefault(import('../client/pages/learning/moder/leaderboard.vue' /* webpackChunkName: "pages/learning/moder/leaderboard" */))
const _7c4201be = () => interopDefault(import('../client/pages/admin/access/_id/index.vue' /* webpackChunkName: "pages/admin/access/_id/index" */))
const _27c224f8 = () => interopDefault(import('../client/pages/admin/sales/_id.vue' /* webpackChunkName: "pages/admin/sales/_id" */))
const _3c835178 = () => interopDefault(import('../client/pages/admin/users/_slug.vue' /* webpackChunkName: "pages/admin/users/_slug" */))
const _71161ea8 = () => interopDefault(import('../client/pages/learning/course/_slug.vue' /* webpackChunkName: "pages/learning/course/_slug" */))
const _799f3928 = () => interopDefault(import('../client/pages/learning/creative/_id.vue' /* webpackChunkName: "pages/learning/creative/_id" */))
const _6d6c5c38 = () => interopDefault(import('../client/pages/learning/expert/_module.vue' /* webpackChunkName: "pages/learning/expert/_module" */))
const _7c4801fd = () => interopDefault(import('../client/pages/learning/expert/_module/_tab.vue' /* webpackChunkName: "pages/learning/expert/_module/_tab" */))
const _f33882d2 = () => interopDefault(import('../client/pages/learning/lecture/_slug.vue' /* webpackChunkName: "pages/learning/lecture/_slug" */))
const _1816ebf5 = () => interopDefault(import('../client/pages/learning/map/_slug.vue' /* webpackChunkName: "pages/learning/map/_slug" */))
const _32564722 = () => interopDefault(import('../client/pages/learning/masterclass/_slug.vue' /* webpackChunkName: "pages/learning/masterclass/_slug" */))
const _f90bf702 = () => interopDefault(import('../client/pages/learning/results/_id.vue' /* webpackChunkName: "pages/learning/results/_id" */))
const _32490e54 = () => interopDefault(import('../client/pages/learning/sale/_slug.vue' /* webpackChunkName: "pages/learning/sale/_slug" */))
const _be34d3be = () => interopDefault(import('../client/pages/admin/access/_id/discounts.vue' /* webpackChunkName: "pages/admin/access/_id/discounts" */))
const _81289590 = () => interopDefault(import('../client/pages/admin/access/_id/promocodes.vue' /* webpackChunkName: "pages/admin/access/_id/promocodes" */))
const _15c8e096 = () => interopDefault(import('../client/pages/learning/course/_course/about.vue' /* webpackChunkName: "pages/learning/course/_course/about" */))
const _778e9c50 = () => interopDefault(import('../client/pages/learning/course/_course/panel/index.vue' /* webpackChunkName: "pages/learning/course/_course/panel/index" */))
const _65605a38 = () => interopDefault(import('../client/pages/learning/course/_course/rating.vue' /* webpackChunkName: "pages/learning/course/_course/rating" */))
const _1ecee19f = () => interopDefault(import('../client/pages/learning/course/_course/students.vue' /* webpackChunkName: "pages/learning/course/_course/students" */))
const _2c629ba2 = () => interopDefault(import('../client/pages/learning/course/_course/panel/editor/index.vue' /* webpackChunkName: "pages/learning/course/_course/panel/editor/index" */))
const _85427cc0 = () => interopDefault(import('../client/pages/learning/course/_course/panel/info.vue' /* webpackChunkName: "pages/learning/course/_course/panel/info" */))
const _4a46bcd6 = () => interopDefault(import('../client/pages/learning/course/_course/panel/landing.vue' /* webpackChunkName: "pages/learning/course/_course/panel/landing" */))
const _42e159a3 = () => interopDefault(import('../client/pages/learning/course/_course/panel/brief/_id.vue' /* webpackChunkName: "pages/learning/course/_course/panel/brief/_id" */))
const _51c71e8a = () => interopDefault(import('../client/pages/learning/course/_course/panel/editor/_id.vue' /* webpackChunkName: "pages/learning/course/_course/panel/editor/_id" */))
const _04d15026 = () => interopDefault(import('../client/pages/learning/course/_course/panel/test/_test.vue' /* webpackChunkName: "pages/learning/course/_course/panel/test/_test" */))
const _be55f21c = () => interopDefault(import('../client/pages/learning/course/_course/module/_module/index.vue' /* webpackChunkName: "pages/learning/course/_course/module/_module/index" */))
const _68998c3e = () => interopDefault(import('../client/pages/learning/course/_course/practice/_module.vue' /* webpackChunkName: "pages/learning/course/_course/practice/_module" */))
const _6815e5b7 = () => interopDefault(import('../client/pages/learning/course/_course/practice/_module/_tab.vue' /* webpackChunkName: "pages/learning/course/_course/practice/_module/_tab" */))
const _5f39c8a2 = () => interopDefault(import('../client/pages/learning/course/_course/module/_module/test.vue' /* webpackChunkName: "pages/learning/course/_course/module/_module/test" */))
const _6ad3d2ec = () => interopDefault(import('../client/pages/learning/course/_course/module/_module/_article/index.vue' /* webpackChunkName: "pages/learning/course/_course/module/_module/_article/index" */))
const _cfe2c4be = () => interopDefault(import('../client/pages/access/_id/index.vue' /* webpackChunkName: "pages/access/_id/index" */))
const _1e67001c = () => interopDefault(import('../client/pages/article/_slug.vue' /* webpackChunkName: "pages/article/_slug" */))
const _4e0bc4fa = () => interopDefault(import('../client/pages/case/_slug.vue' /* webpackChunkName: "pages/case/_slug" */))
const _4124ec1e = () => interopDefault(import('../client/pages/cert/_id.vue' /* webpackChunkName: "pages/cert/_id" */))
const _333e4c42 = () => interopDefault(import('../client/pages/company/_company.vue' /* webpackChunkName: "pages/company/_company" */))
const _221785bc = () => interopDefault(import('../client/pages/company/_company/index.vue' /* webpackChunkName: "pages/company/_company/index" */))
const _3118689d = () => interopDefault(import('../client/pages/company/_company/articles.vue' /* webpackChunkName: "pages/company/_company/articles" */))
const _457014d4 = () => interopDefault(import('../client/pages/company/_company/awards.vue' /* webpackChunkName: "pages/company/_company/awards" */))
const _580aa8e5 = () => interopDefault(import('../client/pages/company/_company/moder-accepted.vue' /* webpackChunkName: "pages/company/_company/moder-accepted" */))
const _827a3090 = () => interopDefault(import('../client/pages/company/_company/moder-cancel.vue' /* webpackChunkName: "pages/company/_company/moder-cancel" */))
const _09c14a33 = () => interopDefault(import('../client/pages/company/_company/news.vue' /* webpackChunkName: "pages/company/_company/news" */))
const _f9ea5ad0 = () => interopDefault(import('../client/pages/company/_company/portfolio.vue' /* webpackChunkName: "pages/company/_company/portfolio" */))
const _5e0eae3e = () => interopDefault(import('../client/pages/company/_company/services.vue' /* webpackChunkName: "pages/company/_company/services" */))
const _4c56f074 = () => interopDefault(import('../client/pages/company/_company/settings/index.vue' /* webpackChunkName: "pages/company/_company/settings/index" */))
const _7eb16fb5 = () => interopDefault(import('../client/pages/company/_company/transactions.vue' /* webpackChunkName: "pages/company/_company/transactions" */))
const _1c5891ae = () => interopDefault(import('../client/pages/company/_company/vacancies.vue' /* webpackChunkName: "pages/company/_company/vacancies" */))
const _7f3f3120 = () => interopDefault(import('../client/pages/company/_company/settings/appearance.vue' /* webpackChunkName: "pages/company/_company/settings/appearance" */))
const _c7391a08 = () => interopDefault(import('../client/pages/company/_company/settings/clients.vue' /* webpackChunkName: "pages/company/_company/settings/clients" */))
const _5fd1fe8f = () => interopDefault(import('../client/pages/company/_company/settings/contacts.vue' /* webpackChunkName: "pages/company/_company/settings/contacts" */))
const _bffc9e32 = () => interopDefault(import('../client/pages/company/_company/settings/delete.vue' /* webpackChunkName: "pages/company/_company/settings/delete" */))
const _4bd6f448 = () => interopDefault(import('../client/pages/company/_company/settings/general.vue' /* webpackChunkName: "pages/company/_company/settings/general" */))
const _80d59c40 = () => interopDefault(import('../client/pages/company/_company/settings/moders.vue' /* webpackChunkName: "pages/company/_company/settings/moders" */))
const _cce359be = () => interopDefault(import('../client/pages/company/_company/settings/pro.vue' /* webpackChunkName: "pages/company/_company/settings/pro" */))
const _7382fd99 = () => interopDefault(import('../client/pages/company/_company/settings/team.vue' /* webpackChunkName: "pages/company/_company/settings/team" */))
const _011198a7 = () => interopDefault(import('../client/pages/editor/_id.vue' /* webpackChunkName: "pages/editor/_id" */))
const _86330254 = () => interopDefault(import('../client/pages/event/_slug.vue' /* webpackChunkName: "pages/event/_slug" */))
const _d45be64e = () => interopDefault(import('../client/pages/event/_slug/index.vue' /* webpackChunkName: "pages/event/_slug/index" */))
const _5e3798ee = () => interopDefault(import('../client/pages/event/_slug/articles/index.vue' /* webpackChunkName: "pages/event/_slug/articles/index" */))
const _3faf5ab1 = () => interopDefault(import('../client/pages/event/_slug/books.vue' /* webpackChunkName: "pages/event/_slug/books" */))
const _5e3e6bde = () => interopDefault(import('../client/pages/event/_slug/certificate.vue' /* webpackChunkName: "pages/event/_slug/certificate" */))
const _2cad0b8e = () => interopDefault(import('../client/pages/event/_slug/homework.vue' /* webpackChunkName: "pages/event/_slug/homework" */))
const _662e6840 = () => interopDefault(import('../client/pages/event/_slug/links.vue' /* webpackChunkName: "pages/event/_slug/links" */))
const _c9b7b53e = () => interopDefault(import('../client/pages/event/_slug/videos.vue' /* webpackChunkName: "pages/event/_slug/videos" */))
const _0419191d = () => interopDefault(import('../client/pages/help-center/_slug.vue' /* webpackChunkName: "pages/help-center/_slug" */))
const _00303646 = () => interopDefault(import('../client/pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _e26d9156 = () => interopDefault(import('../client/pages/ps/_partnership/index.vue' /* webpackChunkName: "pages/ps/_partnership/index" */))
const _50d535b0 = () => interopDefault(import('../client/pages/services/_slug.vue' /* webpackChunkName: "pages/services/_slug" */))
const _4a0b3d39 = () => interopDefault(import('../client/pages/vacancy-editor/_id.vue' /* webpackChunkName: "pages/vacancy-editor/_id" */))
const _29cc0d37 = () => interopDefault(import('../client/pages/vacancy/_slug.vue' /* webpackChunkName: "pages/vacancy/_slug" */))
const _fdbc16be = () => interopDefault(import('../client/pages/access/_id/discounts.vue' /* webpackChunkName: "pages/access/_id/discounts" */))
const _3289b290 = () => interopDefault(import('../client/pages/access/_id/promocodes.vue' /* webpackChunkName: "pages/access/_id/promocodes" */))
const _847fe27a = () => interopDefault(import('../client/pages/vacancy/_vacancy/applications.vue' /* webpackChunkName: "pages/vacancy/_vacancy/applications" */))
const _7a3d180e = () => interopDefault(import('../client/pages/ps/_partnership/_month.vue' /* webpackChunkName: "pages/ps/_partnership/_month" */))
const _0a676fb9 = () => interopDefault(import('../client/pages/_profile.vue' /* webpackChunkName: "pages/_profile" */))
const _f9f4f108 = () => interopDefault(import('../client/pages/_profile/index.vue' /* webpackChunkName: "pages/_profile/index" */))
const _0d986ffa = () => interopDefault(import('../client/pages/_profile/articles.vue' /* webpackChunkName: "pages/_profile/articles" */))
const _7a8aba3c = () => interopDefault(import('../client/pages/_profile/courses.vue' /* webpackChunkName: "pages/_profile/courses" */))
const _5e076c38 = () => interopDefault(import('../client/pages/_profile/feed.vue' /* webpackChunkName: "pages/_profile/feed" */))
const _8349401c = () => interopDefault(import('../client/pages/_profile/portfolio.vue' /* webpackChunkName: "pages/_profile/portfolio" */))
const _262a0da4 = () => interopDefault(import('../client/pages/_profile/services.vue' /* webpackChunkName: "pages/_profile/services" */))
const _2d244483 = () => interopDefault(import('../client/pages/_profile/vacancies.vue' /* webpackChunkName: "pages/_profile/vacancies" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _0185a94e,
    name: "about___uk"
  }, {
    path: "/about-menu",
    component: _c3f362a4,
    name: "about-menu___uk"
  }, {
    path: "/articles",
    component: _0e6ef00c,
    name: "articles___uk"
  }, {
    path: "/business",
    component: _066b37e2,
    name: "business___uk"
  }, {
    path: "/cases",
    component: _7a6aa604,
    name: "cases___uk"
  }, {
    path: "/cases-brandbook",
    component: _085932e7,
    name: "cases-brandbook___uk"
  }, {
    path: "/cdn-trace",
    component: _6404a634,
    name: "cdn-trace___uk"
  }, {
    path: "/changepassword",
    component: _5e8b1fda,
    name: "changepassword___uk"
  }, {
    path: "/community",
    component: _5a252b2a,
    name: "community___uk"
  }, {
    path: "/conferences",
    component: _9b10e650,
    name: "conferences___uk"
  }, {
    path: "/confidential",
    component: _34dd8657,
    name: "confidential___uk"
  }, {
    path: "/cookie",
    component: _7e5ebe73,
    name: "cookie___uk"
  }, {
    path: "/design-system",
    component: _6ac63542,
    children: [{
      path: "",
      component: _0dd9a8a2,
      name: "design-system___uk"
    }, {
      path: "buttons",
      component: _f5c0bc5e,
      name: "design-system-buttons___uk"
    }, {
      path: "cards",
      component: _306bb6b3,
      name: "design-system-cards___uk"
    }, {
      path: "colors",
      component: _5d450e60,
      name: "design-system-colors___uk"
    }, {
      path: "hints",
      component: _c70034c8,
      name: "design-system-hints___uk"
    }, {
      path: "icons",
      component: _27b3222c,
      name: "design-system-icons___uk"
    }, {
      path: "inputs",
      component: _51b50e6e,
      name: "design-system-inputs___uk"
    }, {
      path: "messages",
      component: _67c95cac,
      name: "design-system-messages___uk"
    }, {
      path: "og-image",
      component: _a3fc30f4,
      name: "design-system-og-image___uk"
    }, {
      path: "pagination",
      component: _8a45ac0c,
      name: "design-system-pagination___uk"
    }, {
      path: "segmented",
      component: _bea1a23c,
      name: "design-system-segmented___uk"
    }, {
      path: "tabs",
      component: _face6c44,
      name: "design-system-tabs___uk"
    }, {
      path: "text-input",
      component: _e3d7f4ec,
      name: "design-system-text-input___uk"
    }]
  }, {
    path: "/en",
    component: _213d8413,
    name: "index___en"
  }, {
    path: "/events",
    component: _184232a8,
    children: [{
      path: "",
      component: _21381b2b,
      name: "events___uk"
    }, {
      path: "certificates",
      component: _0cad7b1a,
      name: "events-certificates___uk"
    }, {
      path: "planned",
      component: _1327dedd,
      name: "events-planned___uk"
    }, {
      path: "visited",
      component: _56770d43,
      name: "events-visited___uk"
    }]
  }, {
    path: "/forgotpassword",
    component: _6f315acd,
    name: "forgotpassword___uk"
  }, {
    path: "/help-center",
    component: _05cfba65,
    name: "help-center___uk"
  }, {
    path: "/learning",
    component: _621ef930,
    name: "learning___uk"
  }, {
    path: "/login",
    component: _2a35b1ac,
    name: "login___uk"
  }, {
    path: "/my",
    component: _2b46313b,
    children: [{
      path: "",
      component: _74f99f7e,
      name: "my___uk"
    }, {
      path: "articles",
      component: _5da998c1,
      name: "my-articles___uk"
    }, {
      path: "companies",
      component: _1fd60207,
      name: "my-companies___uk"
    }, {
      path: "feed",
      component: _7ff7acbc,
      name: "my-feed___uk"
    }, {
      path: "jobsearch",
      component: _63851094,
      name: "my-jobsearch___uk"
    }, {
      path: "portfolio",
      component: _2ec0b218,
      name: "my-portfolio___uk"
    }, {
      path: "settings",
      component: _31220cea,
      name: "my-settings___uk"
    }, {
      path: "subscription",
      component: _630599a1,
      name: "my-subscription___uk"
    }, {
      path: "transactions",
      component: _bdb4244e,
      name: "my-transactions___uk"
    }, {
      path: "unsubscribe",
      component: _1d4e6afd,
      name: "my-unsubscribe___uk"
    }, {
      path: "jobsearch/contacts",
      component: _76521b01,
      name: "my-jobsearch-contacts___uk"
    }, {
      path: "jobsearch/history",
      component: _cb418e54,
      name: "my-jobsearch-history___uk"
    }, {
      path: "jobsearch/resumes",
      component: _fc8ccdb0,
      name: "my-jobsearch-resumes___uk"
    }, {
      path: "settings/appearance",
      component: _3be97b08,
      name: "my-settings-appearance___uk"
    }, {
      path: "settings/contacts",
      component: _342a922a,
      name: "my-settings-contacts___uk"
    }, {
      path: "settings/cookie",
      component: _6b0b507c,
      name: "my-settings-cookie___uk"
    }, {
      path: "settings/delete",
      component: _3264d043,
      name: "my-settings-delete___uk"
    }, {
      path: "settings/digest",
      component: _7b2f33dc,
      name: "my-settings-digest___uk"
    }, {
      path: "settings/enter",
      component: _2e1f77f0,
      name: "my-settings-enter___uk"
    }, {
      path: "settings/general",
      component: _d7d55c00,
      name: "my-settings-general___uk"
    }, {
      path: "settings/interests",
      component: _0ca32f21,
      name: "my-settings-interests___uk"
    }, {
      path: "settings/notifications",
      component: _204afe00,
      name: "my-settings-notifications___uk"
    }]
  }, {
    path: "/ps",
    component: _0eb25f15,
    name: "ps___uk"
  }, {
    path: "/rate-retention",
    component: _3158f500,
    name: "rate-retention___uk"
  }, {
    path: "/recruiter",
    component: _1fe5a6ac,
    name: "recruiter___uk"
  }, {
    path: "/refresh",
    component: _641cfb5c,
    name: "refresh___uk"
  }, {
    path: "/registration",
    component: _1bccb508,
    name: "registration___uk"
  }, {
    path: "/services",
    component: _4d67f320,
    name: "services___uk"
  }, {
    path: "/terms",
    component: _69897b70,
    name: "terms___uk"
  }, {
    path: "/transactions",
    component: _3588a7a4,
    name: "transactions___uk"
  }, {
    path: "/vacancies",
    component: _8163c4cc,
    name: "vacancies___uk"
  }, {
    path: "/videos",
    component: _7d279567,
    name: "videos___uk"
  }, {
    path: "/admin/access",
    component: _79dc6f36,
    name: "admin-access___uk"
  }, {
    path: "/admin/companies",
    component: _ced5f7c2,
    name: "admin-companies___uk"
  }, {
    path: "/admin/dashboard",
    component: _93cc3256,
    name: "admin-dashboard___uk"
  }, {
    path: "/admin/library",
    component: _5d76553f,
    name: "admin-library___uk"
  }, {
    path: "/admin/poll-after-trial",
    component: _4ad35226,
    name: "admin-poll-after-trial___uk"
  }, {
    path: "/admin/publications",
    component: _6e2e5a96,
    name: "admin-publications___uk"
  }, {
    path: "/admin/sales",
    component: _6fb5be90,
    name: "admin-sales___uk"
  }, {
    path: "/admin/unsubscriptions",
    component: _083179fe,
    name: "admin-unsubscriptions___uk"
  }, {
    path: "/admin/users",
    component: _39160ee8,
    name: "admin-users___uk"
  }, {
    path: "/admin/vacancies",
    component: _25806c1a,
    name: "admin-vacancies___uk"
  }, {
    path: "/conference/cases-marketing-strategy-pr-2021",
    component: _3a85939a,
    name: "conference-cases-marketing-strategy-pr-2021___uk"
  }, {
    path: "/conference/cases-product-design-2021",
    component: _0cd7e340,
    name: "conference-cases-product-design-2021___uk"
  }, {
    path: "/conference/cases-product-design-2022",
    component: _0cbbb43e,
    name: "conference-cases-product-design-2022___uk"
  }, {
    path: "/conference/cases-smpr-2020",
    component: _c7cbe442,
    name: "conference-cases-smpr-2020___uk"
  }, {
    path: "/conference/cpractice-2020",
    component: _a6243a42,
    name: "conference-cpractice-2020___uk"
  }, {
    path: "/conference/dysarium",
    component: _5e0fd4c6,
    name: "conference-dysarium___uk"
  }, {
    path: "/conference/dysarium-2024",
    component: _665e3557,
    name: "conference-dysarium-2024___uk"
  }, {
    path: "/conference/praktikum-vol-1",
    component: _c9387670,
    name: "conference-praktikum-vol-1___uk"
  }, {
    path: "/en/about",
    component: _0185a94e,
    name: "about___en"
  }, {
    path: "/en/about-menu",
    component: _c3f362a4,
    name: "about-menu___en"
  }, {
    path: "/en/articles",
    component: _0e6ef00c,
    name: "articles___en"
  }, {
    path: "/en/business",
    component: _066b37e2,
    name: "business___en"
  }, {
    path: "/en/cases",
    component: _7a6aa604,
    name: "cases___en"
  }, {
    path: "/en/cases-brandbook",
    component: _085932e7,
    name: "cases-brandbook___en"
  }, {
    path: "/en/cdn-trace",
    component: _6404a634,
    name: "cdn-trace___en"
  }, {
    path: "/en/changepassword",
    component: _5e8b1fda,
    name: "changepassword___en"
  }, {
    path: "/en/community",
    component: _5a252b2a,
    name: "community___en"
  }, {
    path: "/en/conferences",
    component: _9b10e650,
    name: "conferences___en"
  }, {
    path: "/en/confidential",
    component: _34dd8657,
    name: "confidential___en"
  }, {
    path: "/en/cookie",
    component: _7e5ebe73,
    name: "cookie___en"
  }, {
    path: "/en/design-system",
    component: _6ac63542,
    children: [{
      path: "",
      component: _0dd9a8a2,
      name: "design-system___en"
    }, {
      path: "buttons",
      component: _f5c0bc5e,
      name: "design-system-buttons___en"
    }, {
      path: "cards",
      component: _306bb6b3,
      name: "design-system-cards___en"
    }, {
      path: "colors",
      component: _5d450e60,
      name: "design-system-colors___en"
    }, {
      path: "hints",
      component: _c70034c8,
      name: "design-system-hints___en"
    }, {
      path: "icons",
      component: _27b3222c,
      name: "design-system-icons___en"
    }, {
      path: "inputs",
      component: _51b50e6e,
      name: "design-system-inputs___en"
    }, {
      path: "messages",
      component: _67c95cac,
      name: "design-system-messages___en"
    }, {
      path: "og-image",
      component: _a3fc30f4,
      name: "design-system-og-image___en"
    }, {
      path: "pagination",
      component: _8a45ac0c,
      name: "design-system-pagination___en"
    }, {
      path: "segmented",
      component: _bea1a23c,
      name: "design-system-segmented___en"
    }, {
      path: "tabs",
      component: _face6c44,
      name: "design-system-tabs___en"
    }, {
      path: "text-input",
      component: _e3d7f4ec,
      name: "design-system-text-input___en"
    }]
  }, {
    path: "/en/events",
    component: _184232a8,
    children: [{
      path: "",
      component: _21381b2b,
      name: "events___en"
    }, {
      path: "certificates",
      component: _0cad7b1a,
      name: "events-certificates___en"
    }, {
      path: "planned",
      component: _1327dedd,
      name: "events-planned___en"
    }, {
      path: "visited",
      component: _56770d43,
      name: "events-visited___en"
    }]
  }, {
    path: "/en/forgotpassword",
    component: _6f315acd,
    name: "forgotpassword___en"
  }, {
    path: "/en/help-center",
    component: _05cfba65,
    name: "help-center___en"
  }, {
    path: "/en/learning",
    component: _621ef930,
    name: "learning___en"
  }, {
    path: "/en/login",
    component: _2a35b1ac,
    name: "login___en"
  }, {
    path: "/en/my",
    component: _2b46313b,
    children: [{
      path: "",
      component: _74f99f7e,
      name: "my___en"
    }, {
      path: "articles",
      component: _5da998c1,
      name: "my-articles___en"
    }, {
      path: "companies",
      component: _1fd60207,
      name: "my-companies___en"
    }, {
      path: "feed",
      component: _7ff7acbc,
      name: "my-feed___en"
    }, {
      path: "jobsearch",
      component: _63851094,
      name: "my-jobsearch___en"
    }, {
      path: "portfolio",
      component: _2ec0b218,
      name: "my-portfolio___en"
    }, {
      path: "settings",
      component: _31220cea,
      name: "my-settings___en"
    }, {
      path: "subscription",
      component: _630599a1,
      name: "my-subscription___en"
    }, {
      path: "transactions",
      component: _bdb4244e,
      name: "my-transactions___en"
    }, {
      path: "unsubscribe",
      component: _1d4e6afd,
      name: "my-unsubscribe___en"
    }, {
      path: "jobsearch/contacts",
      component: _76521b01,
      name: "my-jobsearch-contacts___en"
    }, {
      path: "jobsearch/history",
      component: _cb418e54,
      name: "my-jobsearch-history___en"
    }, {
      path: "jobsearch/resumes",
      component: _fc8ccdb0,
      name: "my-jobsearch-resumes___en"
    }, {
      path: "settings/appearance",
      component: _3be97b08,
      name: "my-settings-appearance___en"
    }, {
      path: "settings/contacts",
      component: _342a922a,
      name: "my-settings-contacts___en"
    }, {
      path: "settings/cookie",
      component: _6b0b507c,
      name: "my-settings-cookie___en"
    }, {
      path: "settings/delete",
      component: _3264d043,
      name: "my-settings-delete___en"
    }, {
      path: "settings/digest",
      component: _7b2f33dc,
      name: "my-settings-digest___en"
    }, {
      path: "settings/enter",
      component: _2e1f77f0,
      name: "my-settings-enter___en"
    }, {
      path: "settings/general",
      component: _d7d55c00,
      name: "my-settings-general___en"
    }, {
      path: "settings/interests",
      component: _0ca32f21,
      name: "my-settings-interests___en"
    }, {
      path: "settings/notifications",
      component: _204afe00,
      name: "my-settings-notifications___en"
    }]
  }, {
    path: "/en/ps",
    component: _0eb25f15,
    name: "ps___en"
  }, {
    path: "/en/rate-retention",
    component: _3158f500,
    name: "rate-retention___en"
  }, {
    path: "/en/recruiter",
    component: _1fe5a6ac,
    name: "recruiter___en"
  }, {
    path: "/en/refresh",
    component: _641cfb5c,
    name: "refresh___en"
  }, {
    path: "/en/registration",
    component: _1bccb508,
    name: "registration___en"
  }, {
    path: "/en/services",
    component: _4d67f320,
    name: "services___en"
  }, {
    path: "/en/terms",
    component: _69897b70,
    name: "terms___en"
  }, {
    path: "/en/transactions",
    component: _3588a7a4,
    name: "transactions___en"
  }, {
    path: "/en/vacancies",
    component: _8163c4cc,
    name: "vacancies___en"
  }, {
    path: "/en/videos",
    component: _7d279567,
    name: "videos___en"
  }, {
    path: "/learning/brandbook",
    component: _53fe294e,
    name: "learning-brandbook___uk"
  }, {
    path: "/learning/chart",
    component: _1efd7b88,
    name: "learning-chart___uk"
  }, {
    path: "/learning/courses",
    component: _413e7796,
    name: "learning-courses___uk",
    children: [{
      path: ":slug?",
      component: _1d74575e,
      name: "learning-courses-slug___uk"
    }]
  }, {
    path: "/learning/donorua",
    component: _760a4250,
    name: "learning-donorua___uk"
  }, {
    path: "/learning/dysarium-2024",
    component: _9d37aece,
    name: "learning-dysarium-2024___uk"
  }, {
    path: "/learning/expert",
    component: _eb407182,
    name: "learning-expert___uk"
  }, {
    path: "/learning/lectures",
    component: _7d0b4372,
    name: "learning-lectures___uk"
  }, {
    path: "/learning/maps",
    component: _f80863ae,
    name: "learning-maps___uk"
  }, {
    path: "/learning/marathon",
    component: _5cbd1fa8,
    name: "learning-marathon___uk"
  }, {
    path: "/learning/masterclasses",
    component: _db9a063c,
    name: "learning-masterclasses___uk"
  }, {
    path: "/learning/moder",
    component: _e3b1c460,
    name: "learning-moder___uk"
  }, {
    path: "/learning/my",
    component: _c7c5cd84,
    children: [{
      path: "",
      component: _9c958d7e,
      name: "learning-my___uk"
    }, {
      path: "briefs",
      component: _145bd95a,
      name: "learning-my-briefs___uk"
    }, {
      path: "certificates",
      component: _b9af80c6,
      name: "learning-my-certificates___uk"
    }, {
      path: "community",
      component: _5f7acd50,
      name: "learning-my-community___uk"
    }, {
      path: "courses",
      component: _0444f767,
      name: "learning-my-courses___uk"
    }, {
      path: "gains",
      component: _5a516443,
      name: "learning-my-gains___uk"
    }, {
      path: "brief/:slug?",
      component: _46b99138,
      name: "learning-my-brief-slug___uk"
    }]
  }, {
    path: "/learning/podcasts",
    component: _045526fe,
    name: "learning-podcasts___uk"
  }, {
    path: "/learning/specials",
    component: _174af7ec,
    name: "learning-specials___uk"
  }, {
    path: "/learning/subscription",
    component: _552958a2,
    name: "learning-subscription___uk"
  }, {
    path: "/learning/subscription-poll",
    component: _735a4b4d,
    name: "learning-subscription-poll___uk"
  }, {
    path: "/learning/trial",
    component: _61971d74,
    name: "learning-trial___uk"
  }, {
    path: "/moder/dashboard",
    component: _50eed296,
    name: "moder-dashboard___uk"
  }, {
    path: "/admin/access/grant",
    component: _6148d280,
    name: "admin-access-grant___uk"
  }, {
    path: "/admin/library/awarditems",
    component: _283c3a34,
    name: "admin-library-awarditems___uk"
  }, {
    path: "/admin/library/baners",
    component: _2412e5da,
    name: "admin-library-baners___uk"
  }, {
    path: "/admin/library/cities",
    component: _319e6da8,
    name: "admin-library-cities___uk"
  }, {
    path: "/admin/library/jobtitles",
    component: _0c946a05,
    name: "admin-library-jobtitles___uk"
  }, {
    path: "/admin/library/services",
    component: _5342c501,
    name: "admin-library-services___uk"
  }, {
    path: "/admin/library/slides",
    component: _69691245,
    name: "admin-library-slides___uk"
  }, {
    path: "/admin/library/vacancy-specializations",
    component: _321d7623,
    name: "admin-library-vacancy-specializations___uk"
  }, {
    path: "/en/admin/access",
    component: _79dc6f36,
    name: "admin-access___en"
  }, {
    path: "/en/admin/companies",
    component: _ced5f7c2,
    name: "admin-companies___en"
  }, {
    path: "/en/admin/dashboard",
    component: _93cc3256,
    name: "admin-dashboard___en"
  }, {
    path: "/en/admin/library",
    component: _5d76553f,
    name: "admin-library___en"
  }, {
    path: "/en/admin/poll-after-trial",
    component: _4ad35226,
    name: "admin-poll-after-trial___en"
  }, {
    path: "/en/admin/publications",
    component: _6e2e5a96,
    name: "admin-publications___en"
  }, {
    path: "/en/admin/sales",
    component: _6fb5be90,
    name: "admin-sales___en"
  }, {
    path: "/en/admin/unsubscriptions",
    component: _083179fe,
    name: "admin-unsubscriptions___en"
  }, {
    path: "/en/admin/users",
    component: _39160ee8,
    name: "admin-users___en"
  }, {
    path: "/en/admin/vacancies",
    component: _25806c1a,
    name: "admin-vacancies___en"
  }, {
    path: "/en/conference/cases-marketing-strategy-pr-2021",
    component: _3a85939a,
    name: "conference-cases-marketing-strategy-pr-2021___en"
  }, {
    path: "/en/conference/cases-product-design-2021",
    component: _0cd7e340,
    name: "conference-cases-product-design-2021___en"
  }, {
    path: "/en/conference/cases-product-design-2022",
    component: _0cbbb43e,
    name: "conference-cases-product-design-2022___en"
  }, {
    path: "/en/conference/cases-smpr-2020",
    component: _c7cbe442,
    name: "conference-cases-smpr-2020___en"
  }, {
    path: "/en/conference/cpractice-2020",
    component: _a6243a42,
    name: "conference-cpractice-2020___en"
  }, {
    path: "/en/conference/dysarium",
    component: _5e0fd4c6,
    name: "conference-dysarium___en"
  }, {
    path: "/en/conference/dysarium-2024",
    component: _665e3557,
    name: "conference-dysarium-2024___en"
  }, {
    path: "/en/conference/praktikum-vol-1",
    component: _c9387670,
    name: "conference-praktikum-vol-1___en"
  }, {
    path: "/en/learning/brandbook",
    component: _53fe294e,
    name: "learning-brandbook___en"
  }, {
    path: "/en/learning/chart",
    component: _1efd7b88,
    name: "learning-chart___en"
  }, {
    path: "/en/learning/courses",
    component: _413e7796,
    name: "learning-courses___en",
    children: [{
      path: ":slug?",
      component: _1d74575e,
      name: "learning-courses-slug___en"
    }]
  }, {
    path: "/en/learning/donorua",
    component: _760a4250,
    name: "learning-donorua___en"
  }, {
    path: "/en/learning/dysarium-2024",
    component: _9d37aece,
    name: "learning-dysarium-2024___en"
  }, {
    path: "/en/learning/expert",
    component: _eb407182,
    name: "learning-expert___en"
  }, {
    path: "/en/learning/lectures",
    component: _7d0b4372,
    name: "learning-lectures___en"
  }, {
    path: "/en/learning/maps",
    component: _f80863ae,
    name: "learning-maps___en"
  }, {
    path: "/en/learning/marathon",
    component: _5cbd1fa8,
    name: "learning-marathon___en"
  }, {
    path: "/en/learning/masterclasses",
    component: _db9a063c,
    name: "learning-masterclasses___en"
  }, {
    path: "/en/learning/moder",
    component: _e3b1c460,
    name: "learning-moder___en"
  }, {
    path: "/en/learning/my",
    component: _c7c5cd84,
    children: [{
      path: "",
      component: _9c958d7e,
      name: "learning-my___en"
    }, {
      path: "briefs",
      component: _145bd95a,
      name: "learning-my-briefs___en"
    }, {
      path: "certificates",
      component: _b9af80c6,
      name: "learning-my-certificates___en"
    }, {
      path: "community",
      component: _5f7acd50,
      name: "learning-my-community___en"
    }, {
      path: "courses",
      component: _0444f767,
      name: "learning-my-courses___en"
    }, {
      path: "gains",
      component: _5a516443,
      name: "learning-my-gains___en"
    }, {
      path: "brief/:slug?",
      component: _46b99138,
      name: "learning-my-brief-slug___en"
    }]
  }, {
    path: "/en/learning/podcasts",
    component: _045526fe,
    name: "learning-podcasts___en"
  }, {
    path: "/en/learning/specials",
    component: _174af7ec,
    name: "learning-specials___en"
  }, {
    path: "/en/learning/subscription",
    component: _552958a2,
    name: "learning-subscription___en"
  }, {
    path: "/en/learning/subscription-poll",
    component: _735a4b4d,
    name: "learning-subscription-poll___en"
  }, {
    path: "/en/learning/trial",
    component: _61971d74,
    name: "learning-trial___en"
  }, {
    path: "/en/moder/dashboard",
    component: _50eed296,
    name: "moder-dashboard___en"
  }, {
    path: "/learning/moder/grant",
    component: _7593811a,
    name: "learning-moder-grant___uk"
  }, {
    path: "/learning/moder/leaderboard",
    component: _34a96a7b,
    name: "learning-moder-leaderboard___uk"
  }, {
    path: "/en/admin/access/grant",
    component: _6148d280,
    name: "admin-access-grant___en"
  }, {
    path: "/en/admin/library/awarditems",
    component: _283c3a34,
    name: "admin-library-awarditems___en"
  }, {
    path: "/en/admin/library/baners",
    component: _2412e5da,
    name: "admin-library-baners___en"
  }, {
    path: "/en/admin/library/cities",
    component: _319e6da8,
    name: "admin-library-cities___en"
  }, {
    path: "/en/admin/library/jobtitles",
    component: _0c946a05,
    name: "admin-library-jobtitles___en"
  }, {
    path: "/en/admin/library/services",
    component: _5342c501,
    name: "admin-library-services___en"
  }, {
    path: "/en/admin/library/slides",
    component: _69691245,
    name: "admin-library-slides___en"
  }, {
    path: "/en/admin/library/vacancy-specializations",
    component: _321d7623,
    name: "admin-library-vacancy-specializations___en"
  }, {
    path: "/en/learning/moder/grant",
    component: _7593811a,
    name: "learning-moder-grant___en"
  }, {
    path: "/en/learning/moder/leaderboard",
    component: _34a96a7b,
    name: "learning-moder-leaderboard___en"
  }, {
    path: "/",
    component: _213d8413,
    name: "index___uk"
  }, {
    path: "/en/admin/access/:id",
    component: _7c4201be,
    name: "admin-access-id___en"
  }, {
    path: "/en/admin/sales/:id",
    component: _27c224f8,
    name: "admin-sales-id___en"
  }, {
    path: "/en/admin/users/:slug",
    component: _3c835178,
    name: "admin-users-slug___en"
  }, {
    path: "/en/learning/course/:slug?",
    component: _71161ea8,
    name: "learning-course-slug___en"
  }, {
    path: "/en/learning/creative/:id?",
    component: _799f3928,
    name: "learning-creative-id___en"
  }, {
    path: "/en/learning/expert/:module",
    component: _6d6c5c38,
    name: "learning-expert-module___en",
    children: [{
      path: ":tab?",
      component: _7c4801fd,
      name: "learning-expert-module-tab___en"
    }]
  }, {
    path: "/en/learning/lecture/:slug?",
    component: _f33882d2,
    name: "learning-lecture-slug___en"
  }, {
    path: "/en/learning/map/:slug?",
    component: _1816ebf5,
    name: "learning-map-slug___en"
  }, {
    path: "/en/learning/masterclass/:slug?",
    component: _32564722,
    name: "learning-masterclass-slug___en"
  }, {
    path: "/en/learning/results/:id?",
    component: _f90bf702,
    name: "learning-results-id___en"
  }, {
    path: "/en/learning/sale/:slug?",
    component: _32490e54,
    name: "learning-sale-slug___en"
  }, {
    path: "/en/admin/access/:id/discounts",
    component: _be34d3be,
    name: "admin-access-id-discounts___en"
  }, {
    path: "/en/admin/access/:id/promocodes",
    component: _81289590,
    name: "admin-access-id-promocodes___en"
  }, {
    path: "/en/learning/course/:course?/about",
    component: _15c8e096,
    name: "learning-course-course-about___en"
  }, {
    path: "/en/learning/course/:course?/panel",
    component: _778e9c50,
    name: "learning-course-course-panel___en"
  }, {
    path: "/en/learning/course/:course?/rating",
    component: _65605a38,
    name: "learning-course-course-rating___en"
  }, {
    path: "/en/learning/course/:course?/students",
    component: _1ecee19f,
    name: "learning-course-course-students___en"
  }, {
    path: "/en/learning/course/:course?/panel/editor",
    component: _2c629ba2,
    name: "learning-course-course-panel-editor___en"
  }, {
    path: "/en/learning/course/:course?/panel/info",
    component: _85427cc0,
    name: "learning-course-course-panel-info___en"
  }, {
    path: "/en/learning/course/:course?/panel/landing",
    component: _4a46bcd6,
    name: "learning-course-course-panel-landing___en"
  }, {
    path: "/en/learning/course/:course?/panel/brief/:id?",
    component: _42e159a3,
    name: "learning-course-course-panel-brief-id___en"
  }, {
    path: "/en/learning/course/:course?/panel/editor/:id",
    component: _51c71e8a,
    name: "learning-course-course-panel-editor-id___en"
  }, {
    path: "/en/learning/course/:course?/panel/test/:test?",
    component: _04d15026,
    name: "learning-course-course-panel-test-test___en"
  }, {
    path: "/en/learning/course/:course?/module/:module",
    component: _be55f21c,
    name: "learning-course-course-module-module___en"
  }, {
    path: "/en/learning/course/:course?/practice/:module?",
    component: _68998c3e,
    name: "learning-course-course-practice-module___en",
    children: [{
      path: ":tab?",
      component: _6815e5b7,
      name: "learning-course-course-practice-module-tab___en"
    }]
  }, {
    path: "/en/learning/course/:course?/module/:module?/test",
    component: _5f39c8a2,
    name: "learning-course-course-module-module-test___en"
  }, {
    path: "/en/learning/course/:course?/module/:module?/:article",
    component: _6ad3d2ec,
    name: "learning-course-course-module-module-article___en"
  }, {
    path: "/admin/access/:id",
    component: _7c4201be,
    name: "admin-access-id___uk"
  }, {
    path: "/admin/sales/:id",
    component: _27c224f8,
    name: "admin-sales-id___uk"
  }, {
    path: "/admin/users/:slug",
    component: _3c835178,
    name: "admin-users-slug___uk"
  }, {
    path: "/en/access/:id",
    component: _cfe2c4be,
    name: "access-id___en"
  }, {
    path: "/en/article/:slug?",
    component: _1e67001c,
    name: "article-slug___en"
  }, {
    path: "/en/case/:slug?",
    component: _4e0bc4fa,
    name: "case-slug___en"
  }, {
    path: "/en/cert/:id?",
    component: _4124ec1e,
    name: "cert-id___en"
  }, {
    path: "/en/company/:company?",
    component: _333e4c42,
    children: [{
      path: "",
      component: _221785bc,
      name: "company-company___en"
    }, {
      path: "articles",
      component: _3118689d,
      name: "company-company-articles___en"
    }, {
      path: "awards",
      component: _457014d4,
      name: "company-company-awards___en"
    }, {
      path: "moder-accepted",
      component: _580aa8e5,
      name: "company-company-moder-accepted___en"
    }, {
      path: "moder-cancel",
      component: _827a3090,
      name: "company-company-moder-cancel___en"
    }, {
      path: "news",
      component: _09c14a33,
      name: "company-company-news___en"
    }, {
      path: "portfolio",
      component: _f9ea5ad0,
      name: "company-company-portfolio___en"
    }, {
      path: "services",
      component: _5e0eae3e,
      name: "company-company-services___en"
    }, {
      path: "settings",
      component: _4c56f074,
      name: "company-company-settings___en"
    }, {
      path: "transactions",
      component: _7eb16fb5,
      name: "company-company-transactions___en"
    }, {
      path: "vacancies",
      component: _1c5891ae,
      name: "company-company-vacancies___en"
    }, {
      path: "settings/appearance",
      component: _7f3f3120,
      name: "company-company-settings-appearance___en"
    }, {
      path: "settings/clients",
      component: _c7391a08,
      name: "company-company-settings-clients___en"
    }, {
      path: "settings/contacts",
      component: _5fd1fe8f,
      name: "company-company-settings-contacts___en"
    }, {
      path: "settings/delete",
      component: _bffc9e32,
      name: "company-company-settings-delete___en"
    }, {
      path: "settings/general",
      component: _4bd6f448,
      name: "company-company-settings-general___en"
    }, {
      path: "settings/moders",
      component: _80d59c40,
      name: "company-company-settings-moders___en"
    }, {
      path: "settings/pro",
      component: _cce359be,
      name: "company-company-settings-pro___en"
    }, {
      path: "settings/team",
      component: _7382fd99,
      name: "company-company-settings-team___en"
    }]
  }, {
    path: "/en/editor/:id?",
    component: _011198a7,
    name: "editor-id___en"
  }, {
    path: "/en/event/:slug?",
    component: _86330254,
    children: [{
      path: "",
      component: _d45be64e,
      name: "event-slug___en"
    }, {
      path: "articles",
      component: _5e3798ee,
      name: "event-slug-articles___en"
    }, {
      path: "books",
      component: _3faf5ab1,
      name: "event-slug-books___en"
    }, {
      path: "certificate",
      component: _5e3e6bde,
      name: "event-slug-certificate___en"
    }, {
      path: "homework",
      component: _2cad0b8e,
      name: "event-slug-homework___en"
    }, {
      path: "links",
      component: _662e6840,
      name: "event-slug-links___en"
    }, {
      path: "videos",
      component: _c9b7b53e,
      name: "event-slug-videos___en"
    }]
  }, {
    path: "/en/help-center/:slug?",
    component: _0419191d,
    name: "help-center-slug___en"
  }, {
    path: "/en/news/:slug?",
    component: _00303646,
    name: "news-slug___en"
  }, {
    path: "/en/ps/:partnership",
    component: _e26d9156,
    name: "ps-partnership___en"
  }, {
    path: "/en/services/:slug",
    component: _50d535b0,
    name: "services-slug___en"
  }, {
    path: "/en/vacancy-editor/:id?",
    component: _4a0b3d39,
    name: "vacancy-editor-id___en"
  }, {
    path: "/en/vacancy/:slug?",
    component: _29cc0d37,
    name: "vacancy-slug___en"
  }, {
    path: "/learning/course/:slug?",
    component: _71161ea8,
    name: "learning-course-slug___uk"
  }, {
    path: "/learning/creative/:id?",
    component: _799f3928,
    name: "learning-creative-id___uk"
  }, {
    path: "/learning/expert/:module",
    component: _6d6c5c38,
    name: "learning-expert-module___uk",
    children: [{
      path: ":tab?",
      component: _7c4801fd,
      name: "learning-expert-module-tab___uk"
    }]
  }, {
    path: "/learning/lecture/:slug?",
    component: _f33882d2,
    name: "learning-lecture-slug___uk"
  }, {
    path: "/learning/map/:slug?",
    component: _1816ebf5,
    name: "learning-map-slug___uk"
  }, {
    path: "/learning/masterclass/:slug?",
    component: _32564722,
    name: "learning-masterclass-slug___uk"
  }, {
    path: "/learning/results/:id?",
    component: _f90bf702,
    name: "learning-results-id___uk"
  }, {
    path: "/learning/sale/:slug?",
    component: _32490e54,
    name: "learning-sale-slug___uk"
  }, {
    path: "/admin/access/:id/discounts",
    component: _be34d3be,
    name: "admin-access-id-discounts___uk"
  }, {
    path: "/admin/access/:id/promocodes",
    component: _81289590,
    name: "admin-access-id-promocodes___uk"
  }, {
    path: "/en/access/:id?/discounts",
    component: _fdbc16be,
    name: "access-id-discounts___en"
  }, {
    path: "/en/access/:id?/promocodes",
    component: _3289b290,
    name: "access-id-promocodes___en"
  }, {
    path: "/en/event/:slug/about",
    component: _15c8e096,
    name: "event-_slug-about___en"
  }, {
    path: "/en/vacancy/:vacancy?/applications",
    component: _847fe27a,
    name: "vacancy-vacancy-applications___en"
  }, {
    path: "/learning/course/:course?/about",
    component: _15c8e096,
    name: "learning-course-course-about___uk"
  }, {
    path: "/learning/course/:course?/panel",
    component: _778e9c50,
    name: "learning-course-course-panel___uk"
  }, {
    path: "/learning/course/:course?/rating",
    component: _65605a38,
    name: "learning-course-course-rating___uk"
  }, {
    path: "/learning/course/:course?/students",
    component: _1ecee19f,
    name: "learning-course-course-students___uk"
  }, {
    path: "/learning/course/:course?/panel/editor",
    component: _2c629ba2,
    name: "learning-course-course-panel-editor___uk"
  }, {
    path: "/learning/course/:course?/panel/info",
    component: _85427cc0,
    name: "learning-course-course-panel-info___uk"
  }, {
    path: "/learning/course/:course?/panel/landing",
    component: _4a46bcd6,
    name: "learning-course-course-panel-landing___uk"
  }, {
    path: "/learning/course/:course?/panel/brief/:id?",
    component: _42e159a3,
    name: "learning-course-course-panel-brief-id___uk"
  }, {
    path: "/learning/course/:course?/panel/editor/:id",
    component: _51c71e8a,
    name: "learning-course-course-panel-editor-id___uk"
  }, {
    path: "/learning/course/:course?/panel/test/:test?",
    component: _04d15026,
    name: "learning-course-course-panel-test-test___uk"
  }, {
    path: "/en/event/:slug/articles/:article",
    component: _6ad3d2ec,
    name: "event-_slug-articles-_article___en"
  }, {
    path: "/learning/course/:course?/module/:module",
    component: _be55f21c,
    name: "learning-course-course-module-module___uk"
  }, {
    path: "/learning/course/:course?/practice/:module?",
    component: _68998c3e,
    name: "learning-course-course-practice-module___uk",
    children: [{
      path: ":tab?",
      component: _6815e5b7,
      name: "learning-course-course-practice-module-tab___uk"
    }]
  }, {
    path: "/learning/course/:course?/module/:module?/test",
    component: _5f39c8a2,
    name: "learning-course-course-module-module-test___uk"
  }, {
    path: "/learning/course/:course?/module/:module?/:article",
    component: _6ad3d2ec,
    name: "learning-course-course-module-module-article___uk"
  }, {
    path: "/en/ps/:partnership/:month",
    component: _7a3d180e,
    name: "ps-partnership-month___en"
  }, {
    path: "/access/:id",
    component: _cfe2c4be,
    name: "access-id___uk"
  }, {
    path: "/article/:slug?",
    component: _1e67001c,
    name: "article-slug___uk"
  }, {
    path: "/case/:slug?",
    component: _4e0bc4fa,
    name: "case-slug___uk"
  }, {
    path: "/cert/:id?",
    component: _4124ec1e,
    name: "cert-id___uk"
  }, {
    path: "/company/:company?",
    component: _333e4c42,
    children: [{
      path: "",
      component: _221785bc,
      name: "company-company___uk"
    }, {
      path: "articles",
      component: _3118689d,
      name: "company-company-articles___uk"
    }, {
      path: "awards",
      component: _457014d4,
      name: "company-company-awards___uk"
    }, {
      path: "moder-accepted",
      component: _580aa8e5,
      name: "company-company-moder-accepted___uk"
    }, {
      path: "moder-cancel",
      component: _827a3090,
      name: "company-company-moder-cancel___uk"
    }, {
      path: "news",
      component: _09c14a33,
      name: "company-company-news___uk"
    }, {
      path: "portfolio",
      component: _f9ea5ad0,
      name: "company-company-portfolio___uk"
    }, {
      path: "services",
      component: _5e0eae3e,
      name: "company-company-services___uk"
    }, {
      path: "settings",
      component: _4c56f074,
      name: "company-company-settings___uk"
    }, {
      path: "transactions",
      component: _7eb16fb5,
      name: "company-company-transactions___uk"
    }, {
      path: "vacancies",
      component: _1c5891ae,
      name: "company-company-vacancies___uk"
    }, {
      path: "settings/appearance",
      component: _7f3f3120,
      name: "company-company-settings-appearance___uk"
    }, {
      path: "settings/clients",
      component: _c7391a08,
      name: "company-company-settings-clients___uk"
    }, {
      path: "settings/contacts",
      component: _5fd1fe8f,
      name: "company-company-settings-contacts___uk"
    }, {
      path: "settings/delete",
      component: _bffc9e32,
      name: "company-company-settings-delete___uk"
    }, {
      path: "settings/general",
      component: _4bd6f448,
      name: "company-company-settings-general___uk"
    }, {
      path: "settings/moders",
      component: _80d59c40,
      name: "company-company-settings-moders___uk"
    }, {
      path: "settings/pro",
      component: _cce359be,
      name: "company-company-settings-pro___uk"
    }, {
      path: "settings/team",
      component: _7382fd99,
      name: "company-company-settings-team___uk"
    }]
  }, {
    path: "/editor/:id?",
    component: _011198a7,
    name: "editor-id___uk"
  }, {
    path: "/en/:profile",
    component: _0a676fb9,
    children: [{
      path: "",
      component: _f9f4f108,
      name: "profile___en"
    }, {
      path: "articles",
      component: _0d986ffa,
      name: "profile-articles___en"
    }, {
      path: "courses",
      component: _7a8aba3c,
      name: "profile-courses___en"
    }, {
      path: "feed",
      component: _5e076c38,
      name: "profile-feed___en"
    }, {
      path: "portfolio",
      component: _8349401c,
      name: "profile-portfolio___en"
    }, {
      path: "services",
      component: _262a0da4,
      name: "profile-services___en"
    }, {
      path: "vacancies",
      component: _2d244483,
      name: "profile-vacancies___en"
    }]
  }, {
    path: "/event/:slug?",
    component: _86330254,
    children: [{
      path: "",
      component: _d45be64e,
      name: "event-slug___uk"
    }, {
      path: "articles",
      component: _5e3798ee,
      name: "event-slug-articles___uk"
    }, {
      path: "books",
      component: _3faf5ab1,
      name: "event-slug-books___uk"
    }, {
      path: "certificate",
      component: _5e3e6bde,
      name: "event-slug-certificate___uk"
    }, {
      path: "homework",
      component: _2cad0b8e,
      name: "event-slug-homework___uk"
    }, {
      path: "links",
      component: _662e6840,
      name: "event-slug-links___uk"
    }, {
      path: "videos",
      component: _c9b7b53e,
      name: "event-slug-videos___uk"
    }]
  }, {
    path: "/help-center/:slug?",
    component: _0419191d,
    name: "help-center-slug___uk"
  }, {
    path: "/news/:slug?",
    component: _00303646,
    name: "news-slug___uk"
  }, {
    path: "/ps/:partnership",
    component: _e26d9156,
    name: "ps-partnership___uk"
  }, {
    path: "/services/:slug",
    component: _50d535b0,
    name: "services-slug___uk"
  }, {
    path: "/vacancy-editor/:id?",
    component: _4a0b3d39,
    name: "vacancy-editor-id___uk"
  }, {
    path: "/vacancy/:slug?",
    component: _29cc0d37,
    name: "vacancy-slug___uk"
  }, {
    path: "/access/:id?/discounts",
    component: _fdbc16be,
    name: "access-id-discounts___uk"
  }, {
    path: "/access/:id?/promocodes",
    component: _3289b290,
    name: "access-id-promocodes___uk"
  }, {
    path: "/event/:slug/about",
    component: _15c8e096,
    name: "event-_slug-about___uk"
  }, {
    path: "/vacancy/:vacancy?/applications",
    component: _847fe27a,
    name: "vacancy-vacancy-applications___uk"
  }, {
    path: "/event/:slug/articles/:article",
    component: _6ad3d2ec,
    name: "event-_slug-articles-_article___uk"
  }, {
    path: "/ps/:partnership/:month",
    component: _7a3d180e,
    name: "ps-partnership-month___uk"
  }, {
    path: "/:profile",
    component: _0a676fb9,
    children: [{
      path: "",
      component: _f9f4f108,
      name: "profile___uk"
    }, {
      path: "articles",
      component: _0d986ffa,
      name: "profile-articles___uk"
    }, {
      path: "courses",
      component: _7a8aba3c,
      name: "profile-courses___uk"
    }, {
      path: "feed",
      component: _5e076c38,
      name: "profile-feed___uk"
    }, {
      path: "portfolio",
      component: _8349401c,
      name: "profile-portfolio___uk"
    }, {
      path: "services",
      component: _262a0da4,
      name: "profile-services___uk"
    }, {
      path: "vacancies",
      component: _2d244483,
      name: "profile-vacancies___uk"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
